//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { groupsStore, uiStore } from '@/store'

export default {
  name: 'AutodeleteSettings',
  components: {
    'scrollable-area': () => import('@/components/UI/ScrollableArea'),
  },
  data () {
    return {
      enabled: false,
      days: 1,
      hours: 0,
      minutes: 0,
      isLoading: false,
      minLen: 60,
      hourLen: 60 * 60,
      dayLen: 60 * 60 * 24,
    }
  },
  created () {
    this.enabled = this.group.autocleanupAge !== null
    const age = this.group.autocleanupAge
    if (age && age > 0) {
      this.days = Math.floor(age / this.dayLen)
      let remainder = age - this.days * this.dayLen
      this.hours = Math.floor(remainder / this.hourLen)
      remainder -= this.hours * this.hourLen
      this.minutes = Math.round(remainder / this.minLen)
    }
  },
  beforeDestroy () {
    // delete window['@@EntitySelector']
  },
  computed: {
    currentModalPayload () {
      return uiStore.getters.currentModalPayload
    },
    hoursOptions () {
      return [...Array(24).keys()]
    },
    minutesOptions () {
      return [...Array(60).keys()]
    },
    validationRules () {
      return [
        val => typeof val === 'number' || this.$t('common.validationErrors.number'),
        val => val >= 0 || this.$t('common.validationErrors.nonNegativeNumber'),
        val => Number.isInteger(val) || this.$t('common.validationErrors.integer'),
      ]
    },
    group () {
      return groupsStore.state.data[this.currentModalPayload.chatId]
    },
    age () {
      return this.minutes * this.minLen +
        this.hours * this.hourLen +
        this.days * this.dayLen
    },
    currentlyEnabled () {
      return this.group.autocleanupAge !== null
    },
    hasChanges () {
      // enable - disable detection
      if (this.currentlyEnabled !== this.enabled) return true

      // age change detection
      if (this.currentlyEnabled) {
        return this.group.autocleanupAge !== this.age
      }

      return false
    },
    canSave () {
      if (!this.hasChanges) return false
      if (
        this.age <= 0 ||
        this.days < 0 ||
        this.hours < 0 ||
        this.minutes < 0
      ) return false
      return true
    },
  },
  methods: {
    close () {
      uiStore.actions.hideModal()
    },
    async save () {
      this.isLoading = true
      await groupsStore.actions.edit({
        groupId: this.currentModalPayload.chatId,
        payload: { autocleanup_age: this.enabled ? this.age : null },
      })
      this.isLoading = false
      this.close()
    },
    inputValidator (val) {
      val = Number.parseFloat(val)
      if (typeof val !== 'number') return false
      if (val < 0) return false
      if (!Number.isInteger(val)) return false

      return true
    },
    handleDaysInput (val) {
      this.inputValidator(val) && (this.days = val)
    },
    handleHoursInput (val) {
      this.inputValidator(val) && (this.hours = val)
    },
    handleMinutesInput (val) {
      this.inputValidator(val) && (this.minutes = val)
    },
  },
}
